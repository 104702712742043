import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "./station.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import URL from '../../env';

const Station = () => {
    const [sliderCount, setSliderCount] = useState(2)
    // const [examples, setExamples] = useState([
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png",
    //     "/static/img/main/Screenshot.png"
    // ])
    const [examples, setExamples] = useState(null)


    const getExamples = () => {
        axios.get(`${URL}/api/examlpes`).then(
            responce => {
                setExamples(responce.data)
            }
        ).catch(
            error => {                
                setExamples(null)
            }
        )
    }

    useEffect(() => {
        getExamples()        

        if (window.screen.width < 1800) {
            setSliderCount(2)
        }
        if (window.screen.width < 1400) {
            setSliderCount(1)
        }
        if (window.screen.width < 768) {
            setSliderCount(1)
        }
    }, [])



    return (
        <div className='stationDiv' id='station'>
            <div className="boxW">
                <div className="info">
                    <h3>Параметры станции</h3>
                    <div className="img_mobile">
                        <img src="/static/img/station/station-img.png" alt="" />
                    </div>
                    <p>
                        Наша станция имеет небольшие размеры, но при этом позволяет вместить в себя все десять фильтрующих элементов для очистки и накопительный бак для воды.
                    </p>
                    <p>
                        Плюсы данной станции в том, что она разборная как конструктор, что позволяет подстроиться под индивидуальные размеры места размещения.
                    </p>
                </div>
                <div className="img">
                    <img src="/static/img/station/station-img.png" alt="" />
                </div>
                <div className="logo">
                    <img src="/static/img/station/logo.webp" alt="" />
                </div>

            </div>
            <div className='circle'></div>
            <div className="swiperr">
                {examples &&
                    examples.length &&
                    <>
                        <Swiper
                            className='examplesDiv'
                            autoplay
                            spaceBetween={30}
                            navigation={true}
                            pagination={true}
                            slidesPerView={sliderCount}
                            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                        >
                            {examples.map(review =>
                                <SwiperSlide className='examples' key={review}>
                                    <img src={`${review}`} alt="" />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </>
                }
            </div>

        </div>
    )
}

export default Station