import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './review.scss'
import Ligth from '../../elements/ligth/ligth';
import { Helmet } from 'react-helmet';

const Review = () => {
  const reviewsList = [
    {
      'text': '“ Что понравилось: мне не нужно было писать какого-то программистского технического задания. Все ошибки исправлялись вовремя. “',
      'hearts': [1, 2, 3],
      'stars': [1, 1, 1, 1, 0],
      'img': 'freelance-people-education-concept-cheerful-young-attractive-girl-sitting-alone-park-bench-university-working-remote-with-laptop-mobile-phone-look-away-with-pleased-smile 1.png',
      'name': 'Дмитрий Алехин'
    },
    {
      'text': '“ Что понравилось: мне не нужно было писать какого-то программистского технического задания. Все ошибки исправлялись вовремя. “',
      'hearts': [1, 2, 3],
      'stars': [1, 1, 1, 1, 0],
      'img': 'freelance-people-education-concept-cheerful-young-attractive-girl-sitting-alone-park-bench-university-working-remote-with-laptop-mobile-phone-look-away-with-pleased-smile 1.png',
      'name': 'Дмитрий Алехин'
    },
    {
      'text': '“ Что понравилось: мне не нужно было писать какого-то программистского технического задания. Все ошибки исправлялись вовремя. “',
      'hearts': [1, 2, 3],
      'stars': [1, 1, 1, 1, 0],
      'img': 'freelance-people-education-concept-cheerful-young-attractive-girl-sitting-alone-park-bench-university-working-remote-with-laptop-mobile-phone-look-away-with-pleased-smile 1.png',
      'name': 'Дмитрий Алехин'
    },
    {
      'text': '“ Что понравилось: мне не нужно было писать какого-то программистского технического задания. Все ошибки исправлялись вовремя. “',
      'hearts': [1, 2, 3],
      'stars': [1, 1, 1, 1, 0],
      'img': 'freelance-people-education-concept-cheerful-young-attractive-girl-sitting-alone-park-bench-university-working-remote-with-laptop-mobile-phone-look-away-with-pleased-smile 1.png',
      'name': 'Дмитрий Алехин'
    },
    {
      'text': '“ Что понравилось: мне не нужно было писать какого-то программистского технического задания. Все ошибки исправлялись вовремя. “',
      'hearts': [1, 2, 3],
      'stars': [1, 1, 1, 1, 0],
      'img': 'freelance-people-education-concept-cheerful-young-attractive-girl-sitting-alone-park-bench-university-working-remote-with-laptop-mobile-phone-look-away-with-pleased-smile 1.png',
      'name': 'Дмитрий Алехин'
    },
    {
      'text': '“ Что понравилось: мне не нужно было писать какого-то программистского технического задания. Все ошибки исправлялись вовремя. “',
      'hearts': [1, 2, 3],
      'stars': [1, 1, 1, 1, 0],
      'img': 'freelance-people-education-concept-cheerful-young-attractive-girl-sitting-alone-park-bench-university-working-remote-with-laptop-mobile-phone-look-away-with-pleased-smile 1.png',
      'name': 'Дмитрий Алехин'
    },
  ]
  const [sliderCount, setSliderCount] = useState(4)
  const [videoCount, setVideoCount] = useState(3)
  
  useEffect(() => {

    if(window.screen.width < 1800 ){
      setSliderCount(3)
    }
    if(window.screen.width < 1440 ){
      setSliderCount(2)
    }
    if(window.screen.width < 768 ){
      setVideoCount(1)
      setSliderCount(1)
    }
  }, [])
  
  return (
    <div className='reviewDiv'>
            <Helmet>
                <title>Отзывы о системе очистки воды ПИТЬЕРРА в Рязани</title>
                <meta name="description" content="Читать отзывы о нашей домашней системе очистки воды, либо оставить свой справедливый и честный отзыв. Будем благодарны за ваше мнение и обратную связь!" />
            </Helmet>
      <div className="reviewHeaderDiv">        
        <div>
          <h3>отзывы</h3>
          <p>Спасибо, что оставляете обратную связь о нашей системе очистки воды и сервисе! Своими отзывами вы улучшаете сервис нашей компании и с каждым днем мы становимся лучше благодаря вам!</p>
        </div>
        <div>
          <img src="/static/img/reviews/family.png" alt="" />
        </div>
      </div>
      <Ligth zIndex={1} opacity={0.5} x={'0%'} y={'-50%'} height={'500px'} width={'500px'} right={''}/>
      {/* <div className="videReviews">
        <h1 className="mainHeader">Видео Отзывы</h1>
        <Swiper
          className='videoSwiper'
          spaceBetween={30}
          navigation={true}
          pagination={true}
          slidesPerView={videoCount}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        >
          <SwiperSlide><div className='videoDiv' > <img src="/static/img/reviews/couple.png" alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='videoDiv' > <img src="/static/img/reviews/couple.png" alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='videoDiv' > <img src="/static/img/reviews/couple.png" alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='videoDiv' > <img src="/static/img/reviews/couple.png" alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='videoDiv' > <img src="/static/img/reviews/couple.png" alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='videoDiv' > <img src="/static/img/reviews/couple.png" alt="" /></div></SwiperSlide>
        </Swiper>
      </div> */}
      {/* <div className='allReviewSliders'>
        <div className="reviewLogoHeader">
          <img src="/static/img/reviews/yandex_logo.png" alt="" />
        </div>
        <Swiper
          className='reviewSwiper'
          spaceBetween={30}
          navigation={true}
          pagination={true}
          slidesPerView={sliderCount}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        >
          {reviewsList.map(review =>
            <SwiperSlide>
              <div className='review' >
                <p>{review.text}</p>
                <div className='stars'>
                  {review.stars.map(star => star == 1 ?
                    <img src="/static/img/reviews/star.svg" alt="" />
                    :
                    <img src="/static/img/reviews/empty_star.svg" alt="" />
                  )}
                </div>

              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <Ligth zIndex={0} opacity={0.5} x={'0%'} y={'-50%'} height={'500px'} width={'500px'} right={''}/>
        <div className="reviewLogoHeader">
          <img src="/static/img/reviews/2gis_logo.png" alt="" />
        </div>
        <Swiper
          className='reviewSwiper'
          spaceBetween={30}
          navigation={true}
          pagination={true}
          slidesPerView={sliderCount}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        >
         {reviewsList.map(review =>
            <SwiperSlide>
              <div className='review' >
                <p>{review.text}</p>                
                <div className='stars'>
                  {review.stars.map(star => star == 1 ?
                    <img src="/static/img/reviews/star.svg" alt="" />
                    :
                    <img src="/static/img/reviews/empty_star.svg" alt="" />
                  )}
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <Ligth zIndex={0} opacity={0.5} x={'0%'} y={'-50%'} height={'300px'} width={'400px'} right={'0'}/>

        <div className="reviewLogoHeader">
          <img src="/static/img/reviews/avito_logo.png" alt="" />
        </div>
        <Swiper
          className='reviewSwiper'
          spaceBetween={30}
          navigation={true}
          pagination={true}
          slidesPerView={sliderCount}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        >
          
          {reviewsList.map(review =>
            <SwiperSlide className='SwiperSlide'>
              <div className='review' >
                <p>{review.text}</p>                
                <div className='stars'>
                  {review.stars.map(star => star == 1 ?
                    <img src="/static/img/reviews/star.svg" alt="" />
                    :
                    <img src="/static/img/reviews/empty_star.svg" alt="" />
                  )}
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div> */}
      <div className='writeComments'>
            <h1 className='mainMainHeader'>Оставив отзыв о нас,</h1>
            <h1 className='mainHeader'>вы улучшаете сервис компании!</h1>
            <div className='write'>
              <div>
                <img src="/static/img/reviews/yandex.png" alt="" />
                <a target='_blank' href="https://yandex.ru/maps/-/CDqHqR8b">Оставить отзыв</a>
              </div>
              <section className="line"></section>
              <div>
                <img src="/static/img/reviews/2gis.png" alt="" />
                <a target='_blank' href="https://2gis.ru/ryazan/firm/70000001080971113?m=39.646015%2C54.612959%2F16">Оставить отзыв</a>
              </div>
              <section className="line"></section>
              <div>
                <img src="/static/img/reviews/avito.png" alt="" />
                <a target='_blank' href="https://www.avito.ru/ryazan/remont_i_stroitelstvo/filtr_dlya_pitevoy_vody_-_arenda_3730762385">Оставить отзыв</a>
              </div>

            </div>
      </div>
    </div>
  )
}

export default Review