import React, { useState } from 'react';
import './faq.scss';

const Faq = () => {

    const [visibility, setVisibility] = useState([false, false, false, false, false, false, false, false, false, false]);

    const toggleVisibility = (index) => {
        setVisibility((prev) =>
            prev.map((item, i) => (i === index ? !item : item))
        );
    };

    return (
        <div className='faqDiv' id='faq'>
            <h2 className="mainHeader">Вопросы и ответы</h2>
            <div className="boxW">
                <div className='questionDiv'>
                    <div>
                        <p onClick={() => toggleVisibility(0)}>
                            Нужно ли покупать станцию при аренде?

                            <section className={`button ${visibility[0] && 'active'}`}>
                                {visibility[0] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[0] && (
                            <span>
                                НЕТ, станцию не нужно покупать, также не нужно оставлять никакого задатка за станцию.
                            </span>
                        )}
                    </div>
                    <div>
                        <p onClick={() => toggleVisibility(1)}>
                            Нужно ли будет платить еще за что-то помимо ежемесячной оплаты?
                            <section className={`button ${visibility[1] && 'active'}`} >
                                {visibility[1] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[1] && (
                            <span>
                                НЕТ, все предоставляемые комплектующие и услуги такие как установка, демонтаж, выезд техника, обслуживание, замена комплектующих станции, сами комплектующие, замена фильтров, сами фильтры вы получаете БЕСПЛАТНО.
                            </span>
                        )}
                    </div>
                    <div>
                        <p onClick={() => toggleVisibility(2)}>
                            Как часто мы меняем фильтры в станции?
                            <section className={`button ${visibility[2] && 'active'}`} >
                                {visibility[2] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[2] && (
                            <span>
                                Замена производится по регламенту, который прописан в договоре. Так как вода может отличаться в каждом районе и скорость загрязнения фильтров может быть разной, мы не привязываемся строго к графику, если у вас фильтры загрязняются быстрее чем прописано в регламенте, вы можете оставить заявку, и мы заменим их в удобное для вас время.
                            </span>
                        )}
                    </div>
                    <div>
                        <p onClick={() => toggleVisibility(3)}>
                            По каким признакам можно понять, что необходима менять фильтры?
                            <section className={`button ${visibility[3] && 'active'}`} >
                                {visibility[3] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[3] && (
                            <span>
                                При сильном засорении фильтров значительно снижается напор воды в кране и это происходит не резко, а постепенно. Чтобы точно убедиться в необходимости замены фильтров нужно проконсультироваться с нашем менеджером, если необходима замена он согласует с вами время и день приезда техника.
                            </span>
                        )}
                    </div>
                </div>
                <div className='questionDiv'>
                    <div>
                        <p onClick={() => toggleVisibility(4)}>
                            Если у меня смеситель на два крана или уже стоит питьевой кран?
                            <section className={`button ${visibility[4] && 'active'}`} >
                                {visibility[4] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[4] && (
                            <span>
                                Нам не принципиально устанавливать свой кран, можем подключиться к вашему смесителю или питьевому крану, главное, чтобы были все комплектующие для подключения. Если их не будет, то мы можем подобрать их из нашего наличия.
                            </span>
                        )}
                    </div>

                    <div>
                        <p onClick={() => toggleVisibility(5)}>
                            Что делать если намечается переезд с квартиры на квартиру?
                            <section className={`button ${visibility[5] && 'active'}`} >
                                {visibility[5] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[5] && (
                            <span>
                                Необходимо сообщить нам данную информацию о переезде за 14 дней до фактического переезда, мы запланируем демонтаж станции со старого жилья и запланируем тут же установку на новое жилье, это делается БЕСПЛАТНО!
                            </span>
                        )}
                    </div>
                    <div>
                        <p onClick={() => toggleVisibility(6)}>
                            Можно ли установить вашу станцию в частный дом?

                            <section className={`button ${visibility[6] && 'active'}`} >
                                {visibility[6] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[6] && (
                            <span>
                                Станцию можно установить в ваш дом если в доме центральное водоснабжение. Если у вас скважина или колодец, то в этом случае должна быть установлена первоначальная система очистки воды от крупных фракций, зачастую это 2-3 ступени очистки.
                            </span>
                        )}
                    </div>
                    <div>
                        <p onClick={() => toggleVisibility(7)}>
                            Как арендовать станцию системы очистки воды?

                            <section className={`button ${visibility[7] && 'active'}`} >
                                {visibility[7] ?
                                    <img src="/static/img/faq/minus.png" alt="" />
                                    : <img src="/static/img/faq/plus.png" alt="" />
                                }
                            </section>
                        </p>
                        {visibility[7] && (
                            <ol>
                                <li>Как арендовать станцию системы очистки воды?</li>
                                <li>Оставить заявку на нашем сайте, либо связаться с нами по номеру телефона 522-922 который указан в разделе контакты.</li>
                                <li>Вас проконсультирует первый освободившийся менеджер, по любым интересующим вопросам.</li>
                                <li>Согласовываем дату и время монтажа, отталкиваясь от вашего свободного времени.</li>
                                <li>Осуществляем подключение станции.</li>
                                <li>Подписываем договор аренды, приложение и акт приема передачи.</li>
                            </ol>
                        )}
                    </div>
                </div>
            </div>
            <div className='dontbuyblock'>
                <div className="boxW">
                    <div>
                        <h3>Не нашли ответ?</h3>
                        <h4><a href="tel:+74912522922">522-922</a><br />свяжитесь с нами</h4>
                        <a className='linkA' href="#form">
                            Заказать звонок
                            <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.285 12.1393L3.43667 1.31011C3.25849 1.13046 3.04651 0.987876 2.81295 0.890569C2.57938 0.793263 2.32886 0.743164 2.07584 0.743164C1.82282 0.743164 1.5723 0.793263 1.33873 0.890569C1.10517 0.987876 0.893185 1.13046 0.715006 1.31011C0.358025 1.66922 0.157654 2.155 0.157654 2.66136C0.157654 3.16772 0.358025 3.6535 0.715006 4.01261L10.2025 13.5959L0.715006 23.0834C0.358025 23.4426 0.157654 23.9283 0.157654 24.4347C0.157654 24.9411 0.358025 25.4268 0.715006 25.7859C0.892518 25.967 1.1042 26.1111 1.3378 26.2098C1.57139 26.3085 1.82225 26.3599 2.07584 26.3609C2.32943 26.3599 2.58029 26.3085 2.81388 26.2098C3.04748 26.1111 3.25916 25.967 3.43667 25.7859L14.285 14.9568C14.4796 14.7773 14.6348 14.5595 14.741 14.317C14.8472 14.0746 14.902 13.8127 14.902 13.548C14.902 13.2833 14.8472 13.0215 14.741 12.779C14.6348 12.5366 14.4796 12.3188 14.285 12.1393V12.1393Z" fill="#005FAC" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
