import React from 'react'
import ReactPlayer from 'react-player';
import './video.scss'


const Video = () => {
    return (
        <div className='videoDiv'>
            <h1 className='mainHeader mainHeaderVideo'>Где взять чистую питьевую воду?</h1>
            <h1 className='mainHeader mainHeaderVideoMobile'>Где взять чистую воду?</h1>
            {/* <video src="/static/file/video.mp4"></video> */}
            <ReactPlayer
                url="/static/file/video.mp4"
                className="videoPlayer"
                controls={true}
            />
        </div>
    )
}

export default Video