import React from 'react'
import "./installation.scss"

const Installation = () => {
    return (
        <div className='installationDiv' id='installation'>
            <h2 className="mainHeader">
                Установка станции
            </h2>
            <div className="boxW">
                <p>Главный критерий для установки станции является наличие центрального водопровода или собственная скважина с фильтрами грубой очистки. Мы работаем с физическими и юридическими лицами, что позволяет сделать чистую воду доступной для всех.</p>
                <h3>Где можно установить станцию?</h3>
                <section className='pk'>
                    <div>
                        <span>
                            <img src="/static/img/installation/1-1.png" alt="" />
                            Квартира
                        </span>
                        <span>
                            <img src="/static/img/installation/1-2.png" alt="" />
                            Бар
                        </span>
                        <span>
                            <img src="/static/img/installation/1-3.png" alt="" />
                            Салон красоты
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="/static/img/installation/2-1.png" alt="" />
                            Частный дом
                        </span>
                        <span>
                            <img src="/static/img/installation/2-2.png" alt="" />
                            Ресторан
                        </span>
                        <span>
                            <img src="/static/img/installation/2-3.png" alt="" />
                            Кафе
                        </span>
                    </div>
                    <div>
                        <span>
                            <img src="/static/img/installation/3-1.png" alt="" />
                            Офис
                        </span>
                        <span>
                            <img src="/static/img/installation/3-2.png" alt="" />
                            Кофейня
                        </span>
                        <span>
                            <img src="/static/img/installation/3-3.png" alt="" />
                            и так далее
                        </span>
                    </div>

                </section>
                <section className='mobile'>
                    <span>
                        <img src="/static/img/installation/1-1.png" alt="" />
                        Квартира
                    </span>
                    <span>
                        <img src="/static/img/installation/1-2.png" alt="" />
                        Бар
                    </span>
                    <span>
                        <img src="/static/img/installation/1-3.png" alt="" />
                        Салон красоты
                    </span>

                    <span>
                        <img src="/static/img/installation/2-1.png" alt="" />
                        Частный дом
                    </span>
                    <span>
                        <img src="/static/img/installation/2-2.png" alt="" />
                        Ресторан
                    </span>
                    <span>
                        <img src="/static/img/installation/2-3.png" alt="" />
                        Кафе
                    </span>

                    <span>
                        <img src="/static/img/installation/3-1.png" alt="" />
                        Офис
                    </span>
                    <span>
                        <img src="/static/img/installation/3-2.png" alt="" />
                        Кофейня
                    </span>
                    <span>
                        <img src="/static/img/installation/3-3.png" alt="" />
                        и так далее
                    </span>
                </section>
            </div>
        </div>
    )
}

export default Installation