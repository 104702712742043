import React, { useState, useEffect } from 'react';
import './pay.scss'
import Payment from './payment';
import { Helmet } from 'react-helmet';

const Pay = () => {
    const [visibility, setVisibility] = useState([false, false, false, false, false, false, false, false, false, false])
    const [stage, setStage] = useState(null)

    const toggleVisibility = (index) => {
        setVisibility((prev) =>
            prev.map((item, i) => (i === index ? !item : item))
        )
    }
    useEffect(() => {
        if (stage) {
            // Изначально добавляем текущую страницу в историю
            window.history.pushState(null, '', window.location.href)

            const handlePopState = (event) => {
                // Устанавливаем stage в null при нажатии назад
                setStage(null)
                // Оставляем пользователя на той же странице
                window.history.pushState(null, '', window.location.href)
            }

            // Подписка на событие popstate
            window.addEventListener('popstate', handlePopState)

            // Очистка при размонтировании
            return () => {
                window.removeEventListener('popstate', handlePopState)
            }
        }
    }, [stage]);

    return (
        <>
            <Helmet>
                <title>Оплата аренды системы очистки воды в Рязани в 3 клика</title>
                <meta name="description" content="Оплатить аренду станции за 1-6 месяцев в три клика. Выбрать период оплаты аренды станции, заполнить номер договора и ФИО. После этого выбрать способ и оплатить" />
            </Helmet>


            {stage ?
                <div className="payLayout">

                    <h1 className='mainHeader'>ОПЛАТА АРЕНДЫ в 3 клика</h1>
                    <div className="boxW">
                        <div className='questions'>
                            <div className='questionDiv'>
                                <div>
                                    <p onClick={() => toggleVisibility(0)}>
                                        Как правильно оплатить аренду физическому лицу?
                                        <section className={`button ${visibility[0] && 'active'}`}>
                                            {visibility[0] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />
                                            }
                                        </section>
                                    </p>
                                    {visibility[0] && (
                                        <ol>
                                            <li>Выбираем период платежа, двигая ползунок в правую сторону, период можно выбрать от 1 до 6 месяцев.</li>
                                            <li>Заполняем строчку номера договора, это нужно, чтобы оцифровать ваш платеж, будьте внимательны при заполнении! </li>
                                            <li>Заполняем строчку электронная почта, тут вы можете указать любую почту, на нее придет чек об оплате.</li>
                                            <li>Нажимаем кнопку «Оплатить»</li>
                                        </ol>
                                    )}
                                </div>
                                <div>
                                    <p onClick={() => toggleVisibility(1)}>
                                        Как добавить ярлык «ОПЛАТА» на рабочий экран в IOS?

                                        <section className={`button ${visibility[1] && 'active'}`} >
                                            {visibility[1] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[1] && (
                                        <ol>
                                            <li>Откройте эту страницу в браузере Safari на телефоне и нажмите на иконку «Поделиться»</li>
                                            <li>Пролистайте меню вниз и нажмите на пункт – На экран «Домой»</li>
                                            <li>Нажмите «Добавить» в правом верхнем углу. Готово, ярлык появиться на экране.</li>
                                        </ol>
                                    )}
                                </div>
                            </div>
                            <div className='questionDiv'>
                                <div>
                                    <p onClick={() => toggleVisibility(3)}>
                                        Как правильно оплатить аренду юридическому лицу?
                                        <section className={`button ${visibility[3] && 'active'}`} >
                                            {visibility[3] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[3] && (
                                        <ol>
                                            <li>Свяжитесь с нами любым удобным для вас способом.</li>
                                            <li>Мы выставим счет на оплату того периода который вам необходим.</li>
                                        </ol>
                                    )}
                                </div>
                                <div>
                                    <p onClick={() => toggleVisibility(4)}>
                                        Как добавить ярлык «ОПЛАТА» на рабочий экран в Android?
                                        <section className={`button ${visibility[4] && 'active'}`} >
                                            {visibility[4] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[4] && (
                                        <ol>
                                            <li>Откройте эту страницу в любом удобном для вас браузере на телефоне и нажмите на три точки, они могут находиться в правом верхнем углу экрана.</li>
                                            <li>Нажмите на пункт – Добавить на главный экран</li>
                                            <li>Нажмите «Установить» в правом нижнем углу. Готово, ярлык появиться на экране.</li>
                                        </ol>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="banks">
                            <img className='bgImg' src="/static/img/installation/pitierra.webp" alt="" />
                            <div>
                                <img src="/static/img/pay/sber.png" alt="" />
                            </div>
                            <div>
                                <img src="/static/img/pay/tinkoff.png" alt="" />
                            </div>
                            <div>
                                <img src="/static/img/pay/ymoney.png" alt="" />
                            </div>
                            <div>
                                <img src="/static/img/pay/spb.png" alt="" />
                            </div>
                            <div>
                                <img src="/static/img/pay/bankCard.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <Payment stage={stage} />

                    <div className="boxW boxwMobile">
                        <div className='questions'>
                            <div className='questionDiv'>
                                <div>
                                    <p onClick={() => toggleVisibility(0)}>
                                        Как правильно оплатить аренду физическому лицу?
                                        <section className={`button ${visibility[0] && 'active'}`}>
                                            {visibility[0] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[0] && (
                                        <ol>
                                            <li>Выбираем период платежа, двигая ползунок в правую сторону, период можно выбрать от 1 до 6 месяцев.</li>
                                            <li>Заполняем строчку номера договора, это нужно, чтобы оцифровать ваш платеж, будьте внимательны при заполнении! </li>
                                            <li>Заполняем строчку электронная почта, тут вы можете указать любую почту, на нее придет чек об оплате.</li>
                                            <li>Нажимаем кнопку «Оплатить»</li>
                                        </ol>
                                    )}
                                </div>
                                <div>
                                    <p onClick={() => toggleVisibility(1)}>
                                        Как добавить ярлык «ОПЛАТА» на рабочий экран в IOS?

                                        <section className={`button ${visibility[1] && 'active'}`} >
                                            {visibility[1] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[1] && (
                                        <ol>
                                            <li>Откройте эту страницу в браузере Safari на телефоне и нажмите на иконку «Поделиться»</li>
                                            <li>Пролистайте меню вниз и нажмите на пункт – На экран «Домой»</li>
                                            <li>Нажмите «Добавить» в правом верхнем углу. Готово, ярлык появиться на экране.</li>
                                        </ol>
                                    )}
                                </div>
                            </div>
                            <div className='questionDiv'>
                                <div>
                                    <p onClick={() => toggleVisibility(3)}>
                                        Как правильно оплатить аренду юридическому лицу?
                                        <section className={`button ${visibility[3] && 'active'}`} >
                                            {visibility[3] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[3] && (
                                        <ol>
                                            <li>Свяжитесь с нами любым удобным для вас способом.</li>
                                            <li>Мы выставим счет на оплату того периода который вам необходим.</li>
                                        </ol>
                                    )}
                                </div>
                                <div>
                                    <p onClick={() => toggleVisibility(4)}>
                                        Как добавить ярлык «ОПЛАТА» на рабочий экран в Android?
                                        <section className={`button ${visibility[4] && 'active'}`} >
                                            {visibility[4] ?
                                                <img src="/static/img/faq/minus.png" alt="" />
                                                : <img src="/static/img/faq/plus.png" alt="" />

                                            }
                                        </section>
                                    </p>
                                    {visibility[4] && (
                                        <ol>
                                            <li>Откройте эту страницу в любом удобном для вас браузере на телефоне и нажмите на три точки, они могут находиться в правом верхнем углу экрана.</li>
                                            <li>Нажмите на пункт – Добавить на главный экран</li>
                                            <li>Нажмите «Установить» в правом нижнем углу. Готово, ярлык появиться на экране.</li>
                                        </ol>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                :
                <div className="setStage">
                    <h2 className="mainHeader">
                        выберите станцию для оплаты
                    </h2>
                    <div className='stages'>
                        <p onClick={() => setStage(5)}>
                            ПЯТИСТУПЕНЧАТАЯ СТАНЦИЯ
                            <img src="/static/img/filling/arrow.png" alt="" />
                        </p>
                        <p onClick={() => setStage(8)}>
                            ВОСЬМИСТУПЕНЧАТАЯ СТАНЦИЯ
                            <img src="/static/img/filling/arrow.png" alt="" />
                        </p>
                        <p onClick={() => setStage(10)}>
                            десятиступенчатый станция
                            <img src="/static/img/filling/arrow.png" alt="" />
                        </p>
                    </div>
                    <section className="circle"></section>
                </div>
            }
        </>
    )
}

export default Pay