import React from 'react'
import './tarifs.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';

const filters = ["logo(1).png", "logo(2).png", "logo(3).png", "logo(4).png", "logo(5).png", "logo(6).png", "logo(7).png", "logo(8).png", "logo(9).png", "logo(10).png", "logo(11).png", "logo(12).png", "logo(13).png", "logo(14).png", "logo(15).png", "logo(16).png", "logo(17).png"]
const filters_mobile = [["logo(1).png", "logo(2).png", "logo(3).png", "logo(4).png"], ["logo(5).png", "logo(6).png", "logo(7).png", "logo(8).png"], ["logo(9).png", "logo(10).png", "logo(11).png", "logo(12).png"], ["logo(13).png", "logo(14).png", "logo(15).png", "logo(16).png"]]

const Tarifs = () => {
    return (
        <div className='tarifsDiv'>
            <h2 className='mainHeader mobileHeader'>
                Тарифы обслуживания станций
                других производителей
            </h2>
            <h2 className='mainHeader pcHeader'>Тарифы обслуживания станций
                <h2 className='mainHeader'>других производителей</h2>
            </h2>

            <div className="boxW">
                <div className="logos">
                    {filters.map(img =>
                        <img key={img} src={`/static/img/tarif/${img}`} alt="" />
                    )}
                </div>
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    navigation={true}
                    modules={[Navigation]}
                    initialSlide='1'
                    cardsEffect={{
                        perSlideOffset: 20, // Устанавливает отступ между слайдами
                        perSlideRotate: 10, // Угол поворота каждого слайда
                        rotate: false, // Включает вращение
                        slideShadows: false, // Включает тени
                    }}


                    className="tarifSwiper"
                >
                    {filters_mobile.map(list => (
                        <SwiperSlide className='tarifItem'>
                            {list.map(img => (
                                <div>
                                    <img key={img} src={`/static/img/tarif/${img}`} alt="" />
                                </div>
                            ))}
                        </SwiperSlide>

                    ))}
                </Swiper>
                <div className="tarifs">
                    <div className='filtrDiv'>
                        <p>2200 руб.</p>
                        <span>Замена первых 3х фильтров</span>
                        <div className='imgsDiv'>
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_2.png" alt="" />
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                        </div>
                    </div>
                    <div className='filtrDiv'>
                        <p>3200 руб.</p>
                        <span>Замена первых 3х фильтров + мембрана</span>

                        <div className='imgsDiv'>
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_2.png" alt="" />
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_3.png" alt="" />
                        </div>
                    </div>
                    <div className='filtrDiv'>
                        <p>3600 руб.</p>
                        <span>Замена первых 3х фильтров + мембрана + 1 постфильтр</span>

                        <div className='imgsDiv'>
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_2.png" alt="" />
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_3.png" alt="" />
                        </div>
                    </div>
                    <div className='filtrDiv'>
                        <p>3900 руб.</p>
                        <span>Замена первых 3х фильтров + мембрана + 2 постфильтр</span>

                        <div className='imgsDiv'>
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_2.png" alt="" />
                            <img src="/static/img/tarif/fil_1.png" alt="" />
                            <img src="/static/img/tarif/fil_3.png" alt="" />
                            <img src="/static/img/tarif/fil_4.png" alt="" />
                            <img src="/static/img/tarif/fil_4.png" alt="" />
                        </div>
                    </div>
                    <section>
                        <div>
                            <p>2500 руб</p>
                            <span>Установка станции других производителей</span>
                        </div>
                        <div>
                            <p>от 1500 руб.</p>
                            <span>Замена комплектующих оборудования</span>
                        </div>
                        <div>
                            <span><b>БЕСПЛАТНО </b> выезд техника + работа,
                                <br />
                                <br />
                                В стоимость замены уже включены фильтруещие элементы.
                                <img src="/static/img/tarif/idk.png" alt="" />
                            </span>
                        </div>

                    </section>
                </div>
                <div className='dontbuyblock'>
                    <div className="boxW">
                        <div>
                            <h6>Для бесплатной <span><br /></span> консультации и записи</h6>
                            <h4>
                                на обслуживание свяжитесь с нами <a rel="noreferrer" href="tel:+74912522922" className='callA'>522-922</a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Tarifs