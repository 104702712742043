import React, { useState } from 'react'
import './header.scss'
import { Link } from 'react-router-dom'

const Header = () => {
    const [menu, setmenu] = useState(false)
    return (
        <div className='header'>
            
            <div onClick={() => setmenu(!menu)} className={`headerMenuButton ${menu ? 'headerMenuButtonActive' : ''}`}>
                <img src="/static/img/main/menu_button.png" alt="" />
                <p>Меню</p>
                <div className='headerMenu'>
                    <Link to={'/'}>Главная страница</Link>
                    <Link to={'/pay'}>Оплата</Link>
                    <Link to={'/reviews'}>Отзывы</Link>
                    <Link to={'/contact'}>Контакты</Link>
                </div>
            </div>
            <img className='headerLogo' src="/static/img/main/small_logo.svg" alt="" />
            <Link className='mobileButton' to="/pay">Оплатить онлайн</Link>
            <div className='headerContact'>
                <p>
                    <a href="tel:522922">

                    <img src="/static/img/main/u_phone-alt.svg" alt="" />
                    522-922
                    </a>
                </p>
                <p>
                    <img src="/static/img/main/u_location-point.svg" alt="" />
                    Рязань
                </p>
            </div>
        </div>
    )
}

export default Header