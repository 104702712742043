import React from 'react'
import './main.scss'
import Head from '../../elements/head/head'
import Problems from '../../elements/problems/problems'
import HowWork from '../../elements/howWork/howWork'
import Benefice from '../../elements/benefice/benefice'
import Calculator from '../../elements/calculator/calculator'
import OurReviws from '../../elements/ourReviws/ourReviws'
import FormItem from '../../elements/formItem/formItem'
import Faq from '../../elements/faq/faq'
import Video from '../../elements/vode/vode'

const Main = () => {
  return (
    <>
    <Head/>    
    <Video/>
    <Problems/>
    <HowWork/>
    <Benefice/>
    <Calculator/>
    <OurReviws/>
    <FormItem/>
    <Faq/>
    </>
  )
}

export default Main