import React from 'react'
import "./benefice.scss"


const Benefice = () => {
    return (
        <div className='beneficeDiv' id='benefice'>
            <h2 className="mainHeader">Преимущество станции</h2>
            <div className="boxW">
                <div className="infoblocks">
                    <div className="filtrimg white"></div>
                    <div className='filtrinfo'>
                        <div className='white'>
                            <p>Станция «ПИТЬЕРРА-СО10-PREMIUM» </p>
                            <span>разработана командой химиков-технологов, где каждый сотрудник внес неоценимый вклад в разработку данной станции, им удалось добиться идеальных показателей в очистке питьевой воды центральных систем хозяйственно питьевого водоснабжения.</span>
                        </div>
                        <div className='white'>
                            <ul>
                                <li>Нет аналогов в России</li>
                                <li>10 ступеней очистки</li>
                                <li>Уникальное наполнение</li>
                                <li>Компактность установки</li>
                                <li>Полная комплектация станции</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='infowhite white f1'>
                    <p>
                        Станция имеет сбалансированное уникальное наполнение фильтрующих элементов, которые производятся не только на территории России.
                    </p>
                </div>
                <div className='infowhite white f2'>
                    <p>
                    У данной станции нет АНАЛОГОВ на рынке систем очистки воды, что дает колоссальное преимущество!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Benefice