import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { setTrue, setMove } from '../../redux/slice';
import './loading.scss'


const Loading = () => {
    const [position2, setPosition2] = useState(0);
    const loading = useSelector((state) => state.loader.loaded);
    const move = useSelector((state) => state.loader.move);
    const [percent, setPercent] = useState(0)
    const headtitlesRef = useRef(null)
    const loadingDiv = useRef(null)
    const headingRef = useRef(null);
    const number = useRef(null)
    const dispatch = useDispatch();
    const navigation = useLocation();


    useEffect(() => {
        const heading = headingRef.current;
        if (heading) {
            const styleSheet = document.styleSheets[0];
            styleSheet.insertRule(`
              .headingRef:before {
                width: ${percent + 5}%;
                transition: 0.3s;          
              }
            `, styleSheet.cssRules.length);
        }
    }, [percent, loading]);




    useEffect(() => {
        if (loading) {
            const handleScroll = () => {
                const scrollY = window.scrollY;
                if (scrollY * 0.4 < 100) {
                    setPosition2(scrollY * 0.4);
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [loading]);



    useEffect(() => {
        // if (window.location.hostname === 'localhost' ||window.location.hostname === '127.0.0.1'){
        //     loadingDiv.current.style.display = 'none'            

        // }
        var cnt = document.getElementById("count");
        var percent = cnt.innerText;
        var interval;
        interval = setInterval(function () {
            setPercent(percent++)
            if (percent == 95) {
                dispatch(setMove())
            }
            if (percent == 100) {
                clearInterval(interval)
                setPercent(percent++)
                setTimeout(() => {
                    number.current.style.opacity = 0
                    setTimeout(() => {
                        loadingDiv.current.style.opacity = 0
                        headtitlesRef.current.style.position = 'absolute'
                        dispatch(setTrue())
                        setTimeout(() => {
                            loadingDiv.current.style.display = 'none'
                        }, 450);
                    }, 500)
                }, 200)
            }
        }, 30);
    }, [])
    return (
        <>
            {navigation.pathname == '/' || (navigation.pathname != '/' && !loading) ?
                <div
                    ref={headtitlesRef}
                    className="headtitles"
                    style={{ transform: `translateY(${position2}px)`, zIndex: loading && '-1' }}
                >
                    <h2
                        ref={headingRef}
                        className={`headingRef ${!move && 'headingRefActive'}`}
                        data-text="питьерра">
                        питьерра </h2>
                </div>
                :
                <div ref={headtitlesRef}></div>
            }
            <div ref={loadingDiv} className='loadingDiv'>

                <div ref={number} className="percent">
                    <div className="percentNum" id="count">{percent}</div>
                    <div className="percentB">%</div>
                </div>
            </div>
        </>
    )
}

export default Loading