import React, { useEffect } from 'react'
import './ourReviws.scss'
import Aos from 'aos'
import { Link } from 'react-router-dom'

const OurReviws = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])
    return (
        <div className="ourReviewsDiv" id='ourReviewsDiv'>
            <div className="ourReview">
                <img src="/static/img/reviews/yandex.png" alt="" />
                <div className="line"></div>
                <img src="/static/img/reviews/2gis.png" alt="" />
                <div className="line"></div>
                <img src="/static/img/reviews/avito.png" alt="" />
                <Link data-aos="fade-up" to='/reviews'>Больше отзывов тут</Link>
            </div>
        </div>
    )
}

export default OurReviws