import React from 'react'
import Iframe from 'react-iframe'
import './contact.scss'
import Ligth from '../../elements/ligth/ligth'
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <div className='contactDiv'>
            <Helmet>
                <title>Где арендовать систему очистки воды в Рязани? - ПИТЬЕРРА</title>
                <meta name="description" content="Мы находимся по адресу 390013, Рязанская область, г Рязань, ул Ситниковская, д. 69А , офис 22. Телефон для вопросов и заказов: 522-922. Почта: info@pitierra.ru" />
            </Helmet>
            <div className="circle"></div>
            <h1 className="mainHeader">Контакты</h1>
            <div className="contactsTopBlockDiv">
                <div className="contactsTopBlock">
                    <p> <a href="tel:+74912522922"><img src="/static/img/contacts/call.svg" alt="" /> +7 (4912) 522-922</a></p>
                    <p> <img src="/static/img/contacts/mail.svg" alt="" /> info@pitierra.ru</p>
                    <p> <img src="/static/img/contacts/map.svg" alt="" /> 390013, Рязанская область, г Рязань, ул Ситниковская, д. 69А , офис 22</p>
                </div>
            </div>
            <div className='ContactIframeDiv'>
                <Iframe url="https://yandex.ru/map-widget/v1/?um=constructor%3A670bf24abf699cdeb179c672cb0834403e580a5f1b2d0371231c081cc2e44f8d&amp;source=constructor&style=stylers.lightness:invert"
                    z-Index='1'
                    width='100%'
                    height='100%'
                    display="block"
                    position="relative" />
            </div>
            <div className="CompanyCard">
                <a target='_blank' href='/static/file/card.pdf'><img src="/static/img/contacts/file.svg" alt="" />
                    Карточка компании ООО "ПИТЬЕРРА"
                </a>
            </div>
            <h1 className="mainHeader">Часы работы</h1>
            <div className="timesDiv">
                <img className='ico' src="/static/img/contacts/timeico.svg" alt="" />
                <div>
                    <h4><img src="/static/img/contacts/time.svg" alt="" />
                        с 9:00 до 20:00 по мск
                    </h4>
                    <p>Понедельник</p>
                    <p>Вторник</p>
                    <p>Среда</p>
                    <p>Четверг</p>
                    <p>Пятница</p>
                </div>
                <div>
                    <h4><img src="/static/img/contacts/time.svg" alt="" />
                        с 9:00 до 17:00 по мск
                    </h4>
                    <p>Суббота</p>
                    <p>Воскресенье</p>
                </div>
            </div>
        </div>
    )
}

export default Contact