import React, { useState } from 'react'
import './header.scss'
import { Link } from 'react-router-dom'

const Header = () => {
    const [menu, setmenu] = useState(false)
    return (
        <div className='header'>

            <div onClick={() => setmenu(!menu)} className={`headerMenuButton ${menu ? 'headerMenuButtonActive' : ''}`}>
                <img src="/static/img/main/menu_button.png" alt="" />
                <p>Меню</p>
                <div className='headerMenu'>
                    <Link to={'/'}>Главная страница</Link>
                    <Link to={'/pay'}>Оплатить аренду</Link>
                    <Link to={'/service'}>Обслуживание</Link>
                    <Link to={'/reviews'}>Отзывы</Link>
                    <Link to={'/contact'}>Контакты</Link>
                </div>
            </div>
            <Link to={'/'}><img className='headerLogo' src="/static/img/main/small_logo.svg" alt="" /></Link>
            <Link className='mobileButton' to="/pay">Օплатить аренду <img src="/static/img/filling/arrow.png" alt="" /></Link>
            <div className='headerContact'>
                <p>
                    <a className='number_pc' href="tel:+74912522922">
                        <img src="/static/img/main/u_phone-alt.svg" alt="" />
                        +7 (4912) 522-922
                    </a>
                    <a className='number_mobile' href="tel:+74912522922">
                        <img src="/static/img/main/u_phone-alt.svg" alt="" />
                        522-922
                    </a>
                </p>
                <p>
                    <img src="/static/img/main/u_location-point.svg" alt="" />
                    Рязань
                </p>
            </div>
        </div>
    )
}

export default Header