import React, { useState } from 'react'
import './filling.scss'
const date = {
    1: {
        header: "1 ступень: MECHANICAL",
        text: "Картридж изготовлен из вспененного полипропилена 5 мкм, предназначен для очистки от крупных механических примесей (песок, ил, грязь, ржавчина и т.п.), которые присутствуют в любом водопроводе.",
        img: "2"
    },
    2: {
        header: "2 ступень: HQ COAL-P",
        text: "Картридж изготовлен из высококачественного активированного угля. Очищает воду от широкого спектра органических и неорганических веществ (свободный хлор, хлорорганические соединения, пестициды, фенолы, бензолы, нефтепродукты и их производные).",
        img: "1"
    },
    3: {
        header: "3 ступень: MECHANICAL",
        text: "Картридж изготовлен из вспененного полипропилена 1 мкм, предназначен для итоговой доочистки воды перед мембраной, очищает от механических примесей, которые больше 1 мкм, также задерживает угольную пыль и её частицы от второй ступени.",
        img: "2"
    },
    4: {
        header: "4 ступень: MEMBRANE PRO",
        text: "Мембрана 50-100g основной элемент системы обратного осмоса. Это фильтрующий элемент с полупроницаемой структурой и мелкими порами, диаметр которых составляет не более 0,01 мкм. Они пропускают через себя только молекулы воды и кислорода, удаляя более 99,9% примесей, бактерий и солей жесткости.",
        img: "4"
    },
    5: {
        header: "5 ступень: ACTIV COAL-G",
        text: "Постфильтр содержит высококачественный гранулированный активированный уголь для очистки воды после простоя в накопительном баке, устраняет неприятные запахи.",
        img: "5"
    },
    6: {
        header: "6 ступень: INFRARED MINERAL",
        text: "Постфильтр содержит минерализующие шарики в длинном инфракрасном диапазоне. Вода, обработанная этими шариками, содержит больше полезных минералов. При прохождении через минерализатор, молекулы воды разделяются на меньшие группы, обладают лучшей растворимостью и легкостью усвоения организмом полезных веществ.",
        img: "6"
    },
    7: {
        header: "7 ступень: CAALMICA",
        text: "Постфильтр содержит минерализатор 4в1:<br />1. Carbon - выборочно поглощает растворенные в воде примеси.<br />2. Alkaline - повышает pH питьевой воды, потребление которой способствует восстановлению кислотно-щелочного баланса организма.<br />3. Minerals - смягчает состав воды для улучшения органолептических свойств.<br />4. Calcium sulfite balls - шарики с сульфитом кальция с высокой способностью удалять остаточный хлор с чрезвычайно быстрым временем реакции, достигающим 99% и более, что является идеальным высокоэффективным средством для удаления хлора.",
        img: "7"
    },
    8: {
        header: "8 ступень: TOURMALINE LIM",
        text: "Постфильтр содержит турмалиновый картридж, который структурирует молекулы воды, придавая им более активное состояние благодаря эффективному обогащению воды кислородом, тем самым активизируя процессы очищения организма от токсинов. Обогащает воду макро - и микроэлементами: калием, натрием, кальцием и магнием.",
        img: "8"
    },
    9: {
        header: "9 ступень: COCONUT COAL-G",
        text: "Постфильтр содержит высококачественный гранулированный кокосовый уголь для улучшения вкуса и прозрачности, тем самым финально подготавливает воду к употреблению.",
        img: "9"
    },
    10: {
        header: "10 ступень: SHUNGITE",
        text: "Постфильтр содержит гранулы природного минерала шунгит - это порода, близкая к графиту и антрациту. Содержит в составе до 98% углерода. Углерод, наряду с водородом и кислородом, способствует процессу высвобождения энергии через глюкозу в организме, вырабатывая аденозинтрифосфат, который считается источником энергии на клеточном уровне.",
        img: "10"
    }
}

const Filling = () => {
    const [filter, setFilter] = useState(1)
    const [showAll, setShowAll] = useState(false)
    return (
        <div className='fillingDiv' id='filling'>
            <div className="mainHeader">Наполнение станции</div>
            <div className="boxW">
                <div className="buttons_mobile">
                    {Object.keys(date).map((key, i) => (
                        <div key={key} className='fillButton' onClick={() => setFilter(key)}>
                            <p>{i + 1} ступень</p>
                        </div>
                    ))}
                </div>

                <div className="filters">
                    <h3>{date[filter]['header']}</h3>
                    <p dangerouslySetInnerHTML={{ __html: date[filter]['text'] }}
                        style={{
                            textAlign: date[filter]['img'] == "7" && "left",
                        }}
                    ></p>
                    <img src={`/static/img/filling/${date[filter]['img']}.webp`} alt="" />
                </div>
                <div className="buttons">
                    {Object.keys(date).slice(showAll ? 5 : 0, showAll ? 10 : 5).map(key => (
                        <div key={key} className='fillButton' onClick={() => setFilter(key)}>
                            <p>{date[key]['header']}</p>
                            <button>
                                <img src="/static/img/filling/arrow.png" alt="" />
                            </button>
                        </div>
                    ))}
                    <span className={`open ${showAll && 'opened'}`} onClick={() => setShowAll(!showAll)}>{!showAll ? "Смотреть еще 5 фильтров" : 'Скрить'}</span>
                </div>
            </div>
        </div >
    )
}

export default Filling