// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/static/img/problems/texture.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoDiv {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 80px;
  box-sizing: border-box;
  padding: 100px 0;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.videoDiv .videoPlayer {
  margin-top: 100px;
  max-width: 50% !important;
  width: 60% !important;
  height: auto !important;
}
.videoDiv video {
  border-radius: 25px;
  box-shadow: 5px 5px 8px 0px rgba(221, 221, 221, 0.2);
}

.mainHeaderVideoMobile {
  display: none;
}

@media (max-width: 1440px) {
  .videoDiv {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}
@media (max-width: 1024px) {
  .videoDiv .videoPlayer {
    max-width: 60% !important;
    width: 70% !important;
  }
}
@media (max-width: 768px) {
  .mainHeaderVideoMobile {
    display: flex;
  }
  .mainHeaderVideo {
    display: none;
  }
  .videoDiv .videoPlayer {
    margin-top: 70px;
    max-width: 90% !important;
    width: 90% !important;
  }
  .videoDiv .videoPlayer video {
    border-radius: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/elements/vode/video.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,qBAAA;EACA,sBAAA;EACA,gBAAA;EACA,oBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACI;EACI,iBAAA;EACA,yBAAA;EACA,qBAAA;EACA,uBAAA;AACR;AAEI;EACI,mBAAA;EACA,oDAAA;AAAR;;AAIA;EACI,aAAA;AADJ;;AAKA;EACI;IACI,iBAAA;IACA,oBAAA;EAFN;AACF;AAKA;EAGQ;IACI,yBAAA;IACA,qBAAA;EALV;AACF;AASA;EACI;IACI,aAAA;EAPN;EASE;IACI,aAAA;EAPN;EAUM;IACI,gBAAA;IACA,yBAAA;IACA,qBAAA;EARV;EASU;IACI,mBAAA;EAPd;AACF","sourcesContent":[".videoDiv {\n    background-image: url(../../../public/static/img/problems/texture.png);\n    background-size: 80px;\n    box-sizing: border-box;\n    padding: 100px 0;\n    padding-bottom: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n\n    .videoPlayer {\n        margin-top: 100px;\n        max-width: 50% !important;\n        width: 60% !important;\n        height: auto !important;\n    }\n\n    video {\n        border-radius: 25px;\n        box-shadow: 5px 5px 8px 0px rgba(221, 221, 221, 0.2);\n\n    }\n}\n.mainHeaderVideoMobile{\n    display: none;\n}\n\n\n@media (max-width: 1440px) {\n    .videoDiv {\n        padding-top: 60px;\n        padding-bottom: 30px;\n    }\n}\n\n@media (max-width: 1024px) {\n\n    .videoDiv {\n        .videoPlayer {\n            max-width: 60% !important;\n            width: 70% !important;\n        }\n    }\n}\n\n@media (max-width: 768px) {\n    .mainHeaderVideoMobile{\n        display: flex;\n    }\n    .mainHeaderVideo{\n        display: none;\n    }\n    .videoDiv {\n        .videoPlayer {\n            margin-top: 70px;\n            max-width: 90% !important;\n            width: 90% !important;\n            video{\n                border-radius: 10px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
