import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './review.scss'
import { Helmet } from 'react-helmet';

const Review = () => {
  return (
    <div className='reviewDiv'>
      <Helmet>
        <title>Отзывы о системе очистки воды ПИТЬЕРРА в Рязани</title>
        <meta name="description" content="Читать отзывы о нашей домашней системе очистки воды, либо оставить свой справедливый и честный отзыв. Будем благодарны за ваше мнение и обратную связь!" />
      </Helmet>
      <div className="boxW">
        <div className="reviewHeaderDiv">
          <div className='headers'>
            <h3>отзывы</h3>
            <p>Спасибо, что оставляете обратную связь о нашей системе очистки воды и сервисе! Своими отзывами вы улучшаете сервис нашей компании и с каждым днем мы становимся лучше благодаря вам!</p>
          </div>

          <div className='images'>
            <div>
              <img src="/static/img/reviews/example_1.png" alt="" />
            </div>
            <div className='mobile'>
              <img src="/static/img/reviews/example_4.png" alt="" />
            </div>
            <div>
              <img src="/static/img/reviews/example_2.png" alt="" />
            </div>
            <div>
              <img src="/static/img/reviews/example_3.png" alt="" />
              <img src="/static/img/reviews/example_4.png" alt="" />
            </div>
            <div>
              <img src="/static/img/reviews/example_5.png" alt="" />
            </div>
          </div>
        </div>
        <div className='writeComments'>
          <div className='write'>
            <h1 className='mainMainHeader'>Оставив отзыв о нас,</h1>
            <h1 className='mainHeader'>вы улучшаете сервис компании!</h1>
            <div>
              <img src="/static/img/reviews/yandex.png" alt="" />
              <a target='_blank' href="https://yandex.ru/maps/-/CDqHqR8b">посмотреть отзыв</a>
            </div>
            <section className="line"></section>
            <div>
              <img src="/static/img/reviews/2gis.png" alt="" />
              <a target='_blank' href="https://2gis.ru/ryazan/firm/70000001080971113?m=39.646015%2C54.612959%2F16">посмотреть отзыв</a>
            </div>
            <section className="line"></section>
            <div>
              <img src="/static/img/reviews/avito.png" alt="" />
              <a target='_blank' href="https://www.avito.ru/ryazan/remont_i_stroitelstvo/filtr_dlya_pitevoy_vody_-_arenda_3730762385">посмотреть отзыв</a>
            </div>
          </div>
        </div>
      </div>
      <div className="circle"></div>
    </div>
  )
}

export default Review