import React from 'react'
import "./arend.scss"

const Arend = () => {
    return (
        <div className='arendDiv' id='arend'>
            <h1 className="mainHeader">Плюсы аренды станции</h1>
            <img className='iskWhatsThis' src="/static/img/arend/idk.webp" alt="" />
            <div className="boxW">
                <div className="headers">
                    <div>
                        <p>покапать фильтры <br />
                            покупать станцию <br />
                            покапать детали станции</p>
                        <h3>Не нужно</h3>
                    </div>
                    <div>
                        <p>выезд техника <br />
                            установка станции <br />
                            обслуживание станции <br />
                            замена фильтров по графику</p>
                        <h3>БЕСПЛАТНО</h3>
                    </div>
                </div>
                <div className="info">
                    <h3>Выбирая аренду станции вам больше <b>НЕ НУЖНО БУДЕТ</b></h3>
                    <section>
                        <div >
                            <img src="/static/img/arend/icon_1.png" alt="" />
                            <ul>
                                <li>Ходить за водой</li>
                                <li>Носить тяжелые бутылки</li>
                                <li>Хранить 5-10 литровые бутылки</li>
                                <li>Контролировать остаток воды дома</li>
                            </ul>
                        </div>
                        <div >
                            <img src="/static/img/arend/icon_2.png" alt="" />
                            <ul>
                                <li>Ожидать доставку воды</li>
                                <li>Хранить 15-20 литровые бутылки</li>
                                <li>Подстраиваться под график доставки</li>
                                <li>Пользоваться помой для налива воды</li>
                            </ul>
                        </div>
                        <div >
                            <img src="/static/img/arend/icon_3.png" alt="" />
                            <ul>
                                <li>Переплачивать за воду</li>
                                <li>Идти за водой в непогоду</li>
                                <li>Загрязнять экологию пластиком </li>
                                <li>Терять много времени в очереди на кассе</li>
                            </ul>
                        </div>
                        <div >
                            <img src="/static/img/arend/icon_4.png" alt="" />
                            <ul>
                                <li>Искать подходящие фильтры</li>
                                <li>Покупать фильтры </li>
                                <li>Менять самостоятельно фильтры</li>
                                <li>Контролировать промежуток замен фильтров</li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Arend