import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import './head.scss';
import { Helmet } from 'react-helmet';

const Head = () => {
  const [position1, setPosition1] = useState(0);  
  const [position3, setPosition3] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const loading = useSelector((state) => state.loader.loaded);
  const percent = useSelector((state) => state.loader.percent);
  const move = useSelector((state) => state.loader.move);
  const headingRef = useRef(null);

  useEffect(() => {
    const heading = headingRef.current;
    if (heading) {
      const styleSheet = document.styleSheets[0];
      styleSheet.insertRule(`
        .headingRef:before {
          width: ${percent}%;
          transition: 0.3s;          
        }
      `, styleSheet.cssRules.length);
    }
  }, [percent, loading]);




  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setPosition1(scrollY * 0.5);      
      setPosition3(scrollY * 0.1);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  useEffect(() => {
    if (loading !== false) {
      const startAnimation = () => {
        const endValue = 13.5; // Конечное значение
        const duration = 2000; // Общая продолжительность анимации (миллисекунды)
        const stepTime = 25; // Интервал обновления (миллисекунды)

        const steps = duration / stepTime; // Количество шагов
        const increment = endValue / steps; // Шаг увеличения

        let currentValue = 0; // Начальное значение

        const interval = setInterval(() => {
          currentValue += increment;
          if (currentValue >= endValue) {
            currentValue = endValue;
            clearInterval(interval); // Остановка интервала при достижении конечного значения
          }
          setPercentage(currentValue.toFixed(1)); // Округление до одного знака после запятой
        }, stepTime);
      };

      const delay = 0; // Задержка перед началом анимации (миллисекунды)
      const timeout = setTimeout(startAnimation, delay);

      return () => clearTimeout(timeout); // Очистка таймера при размонтировании компонента
    }
  }, [loading]);



  return (
    <>
      <div className="headDiv" id='headDiv'>
        <Helmet>
          <title>Система очистки воды ПИТЬЕРРА в Рязани</title>
          <meta name="description" content="Пятиступенчатая система очистки воды для домашней установки и использования. Аренда в Рязани — всего 490 руб/месяц. От заявки до установки — 2 дня. Тел: 522-922" />
        </Helmet>

        <div
          className='fonmask fonmask0'
          style={{ transform: `translateY(-${position1}px)` }}
        ></div>
        
        <div
          className='fonmask fonmask1'
          style={{ transform: `translateY(${position3}px)` }}
        ></div>

        <div className={`boxW ${!loading && "boxWActive"}`}>
          <div className='headInfo'>
            <img src="/static/img/main/icon_999.webp" alt="" />
            <p>Сокращает содержание <br /> вредных примесей в </p>
            <h4>{percentage}<span>раз</span></h4>
            <hr />
            <ul>
              <li>Нет аналогов в России</li>
              <li>10 ступеней очистки</li>
              <li>Уникальное наполнение</li>
            </ul>
          </div>
          <div className='headImg'>
            <img src="/static/img/main/filter_img.webp" alt="" />
          </div>
          <h5>CO10-PREMIUM</h5>
        </div>
      </div>
      <div className='dontbuyblock'>
        <div className="boxW">
          <div>
            <h3>НЕ ПОКУПАЙТЕ</h3>
            <h4>
              станцию очистки воды,
              <br />
              а просто возьмите ее в </h4>
            <a className='linkA' href="#arend">
              АРЕНДУ!
              <svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.285 12.1393L3.43667 1.31011C3.25849 1.13046 3.04651 0.987876 2.81295 0.890569C2.57938 0.793263 2.32886 0.743164 2.07584 0.743164C1.82282 0.743164 1.5723 0.793263 1.33873 0.890569C1.10517 0.987876 0.893185 1.13046 0.715006 1.31011C0.358025 1.66922 0.157654 2.155 0.157654 2.66136C0.157654 3.16772 0.358025 3.6535 0.715006 4.01261L10.2025 13.5959L0.715006 23.0834C0.358025 23.4426 0.157654 23.9283 0.157654 24.4347C0.157654 24.9411 0.358025 25.4268 0.715006 25.7859C0.892518 25.967 1.1042 26.1111 1.3378 26.2098C1.57139 26.3085 1.82225 26.3599 2.07584 26.3609C2.32943 26.3599 2.58029 26.3085 2.81388 26.2098C3.04748 26.1111 3.25916 25.967 3.43667 25.7859L14.285 14.9568C14.4796 14.7773 14.6348 14.5595 14.741 14.317C14.8472 14.0746 14.902 13.8127 14.902 13.548C14.902 13.2833 14.8472 13.0215 14.741 12.779C14.6348 12.5366 14.4796 12.3188 14.285 12.1393V12.1393Z" fill="#005FAC" />
              </svg>

            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Head;
