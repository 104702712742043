// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ligtDiv {
  width: 400px;
  height: 400px;
  background-color: #458bc7;
  position: absolute;
  filter: blur(100px);
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .ligtDiv {
    max-width: 50%;
    max-height: 30vw;
    filter: blur(8vw);
  }
}`, "",{"version":3,"sources":["webpack://./src/elements/ligth/ligth.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI;IACI,cAAA;IACA,gBAAA;IACA,iBAAA;EACN;AACF","sourcesContent":[".ligtDiv {\n    width: 400px;\n    height: 400px;\n    background-color: #458bc7;\n    position: absolute;\n    filter: blur(100px);\n    border-radius: 50%;\n}\n\n@media (max-width: 1024px) {\n    .ligtDiv {\n        max-width: 50%;\n        max-height: 30vw;\n        filter: blur(8vw);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
