import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loaded: false,
    percent: 0,
    move: false,
};

export const loadingSlice = createSlice({
    name: 'loadingState',
    initialState,
    reducers: {
        setTrue: (state) => {
            state.loaded = true;
        },
        setFalse: (state) => {
            state.loaded = false;
        },
        setMove: (state) => {
            state.move = true;
        },
        setPercent: (state, action) => {
            state.percent = action.payload; // Устанавливаем значение из payload
        },
    },
});

export const { setTrue, setFalse, setPercent, setMove } = loadingSlice.actions;

export default loadingSlice.reducer;
