import React from 'react'
import './arendCost.scss'

const ArendCost = () => {
    return (
        <div className='arendCostDiv'>
            <div className="boxW">
                <div className="costcost">
                    <img src="/static/img/cost/arrow.png" alt="" />
                    <h2>
                        <h3>Стоимость станции</h3>
                        27 000 руб
                        <h3>не нужно покупать!</h3>
                    </h2>
                </div>
                <div className="constInfo">
                    <h3><b>Аренда</b> станции</h3>
                    <h3>CO10-PREMIUM всего за</h3>
                    <h2>690 руб в месяц !</h2>
                    <div className='images'>
                        <div>
                            <img src="/static/img/cost/filter1.png" alt="" />
                        </div>
                        <div>
                            <img src="/static/img/cost/filter2.png" alt="" />
                        </div>
                        <div>
                            <img src="/static/img/cost/filter3.png" alt="" />
                        </div>
                    </div>
                </div>
            <img className='bgImg' src="/static/img/cost/filter.webp" alt="" />
            </div>
        </div>
    )
}

export default ArendCost