import React, { useEffect, useState } from 'react'
import './faq.scss'
import Form from '../formItem/form';
import Ligth from '../ligth/ligth';
import Aos from 'aos'

const Faq = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])
    const [form, setForm] = useState(false)
    const [qouestions, setQouestions] = useState([
        {
            'key': '1', 'qouestion': 'Как арендовать вашу станцию системы очистки воды?', 'answers': [
                'Оставить заявку на нашем сайте: https://piterra.ru либо связаться с нами по номеру телефона 8 который указан в верхней части сайта или в разделе контакты.',
                'Вас проконсультирует первый освободившийся менеджер, по любым интересующим вопросам.',
                'Согласовываем дату и время монтажа, отталкиваясь от вашего свободного времени.',
                'Осуществляем подключение станции.',
                'Подписываем договор аренды и акт приема передачи.',
            ],
            'active': false

        },
        {
            'key': '2', 'qouestion': 'Если у меня смеситель на два крана?', 'answer': 'В этом случае мы можем также установить нашу систему очистки воды, мы не привязываемся к своему крану при установке системы. Ваш смеситель может использоваться в штатном режиме, но прежде, чем осуществить монтаж, техник должен убедиться, что наша пластиковая трубка для системы очистки подойдёт по размеру. Также это можно сделать дистанционно.', 'active': false
        },
        {
            'key': '3', 'qouestion': 'Чем отличается ваша 5ти ступенчатая система очистки воды от системы очистки воды с осмос?', 'answers': [
                'Главное преимущество нашей системы очистки воды в прямой подаче воды через систему в питьевой кран, в отличие от осмоса, который на 1 л чистой воды выбрасывает от 6 до 10 л воды и более, не прошедшей через мембрану. Тем самым при использовании нашей станции, вы кратно сокращаете расход воды и, как следствие, экономите на коммунальных платежах.',
                'У нас беспрерывный доступ к фильтрованной воде, в отличие от осмоса, где установлен накопительный бак для воды. Если вам в моменте потребовался большой объем воды, вы ограничены тем объемом, который у вас есть в накопительном баке. В последствии придется ждать длительный период чтобы бак наполнился, а вы можете быть ограничены во времени.',
                'В отличие от системы очистки воды с осмос, наша станция не имеет накопительного бака для хранения воды, тем самым она намного компактней и в 99% случаях беспрепятственно помещается в кухонный шкаф.',
            ],
            'active': false
        },
        {
            'key': '4', 'qouestion': 'По каким признакам можно понять, что необходима замена элементов фильтрации?', 'answer': 'При сильном засорении фильтров значительно снижается напор воды в кране и это происходит не резко, а постепенно. Чтобы точно убедиться в необходимости замены фильтров, нужно проконсультироваться с нашим менеджером. Если необходима замена, он согласовывает с вами время и день приезда техника.', 'active': false
        },
        {
            'key': '5', 'qouestion': 'Можно ли установить вашу станцию в частный дом?', 'answer': 'Станцию можно установить в ваш дом, если в доме центральное водоснабжение. Если у вас скважина или колодец, то в этом случае должна быть установлена первоначальная система очистки воды от крупных фракций, зачастую это 2-3 ступени очистки.', 'active': false
        },

    ])

    function updateSelectedByTitle(titleToUpdate, newSelectedValue) {
        setQouestions(qouestions => {
            return qouestions.map(item => {
                if (item.key === titleToUpdate) {
                    if (item.active) {
                        return { ...item, active: false }
                    }
                    return { ...item, active: true }
                }
                return { ...item, active: false }
            });
        });
    }
    return (
        <>
            <Ligth zIndex={1} opacity={0.4} x={'0%'} y={'0%'} height={'500px'} width={'500px'} />
            {form &&
                <Form setForm={setForm} />}
            <div className="faqDiv" id='faqDiv'>
                <h1 className="mainHeader">Вопросы и ответы</h1>
                <div className='faqs'>
                    <img data-aos="zoom-in-up" className='marks' src="/static/img/faq/marks.png" alt="" />
                    {qouestions.map(faq =>
                        <div onClick={() => updateSelectedByTitle(faq.key)} key={faq.key} className='faq'>
                            <p>{faq.qouestion} <img className={faq.active && 'active'} src="/static/img/form/u_angle-down.svg" alt="" /></p>
                            {faq.active &&
                                <>
                                    {
                                        faq.answers &&
                                        <ol>
                                            {faq.answers.map(answer => <li>{answer}</li>)}
                                        </ol>
                                    }
                                    {faq.answer &&
                                        <h6>{faq.answer}</h6>
                                    }
                                </>
                            }
                        </div>
                    )}
                </div>
                <h1 className="mainHeader">Не нашли ответ?</h1>
                <div className='numberBlockSection'>
                    <div className='numberBlockDiv'>
                        <p data-aos="fade-up">522-922</p>
                        <Ligth zIndex={0} opacity={0.5} x={'0%'} y={'-50%'} height={'500px'} width={'500px'} right={''} />
                        <span>свяжитесь с нами</span>
                        <button onClick={() => setForm(true)}>Заказать звонок</button>
                        <img data-aos="zoom-out-left" className='arrow' src="/static/img/faq/arrow.png" alt="" />
                        <img className='arrow2' src="/static/img/faq/arrow2.png" alt="" />
                        <img data-aos="zoom-in-right" className='call' src="/static/img/faq/call.png" alt="" />
                        <img className='marks' src="/static/img/faq/messages.png" alt="" />
                    </div>
                </div>
                <Ligth zIndex={0} opacity={0.5} x={'0%'} y={'-50%'} height={'500px'} width={'500px'} right={'0px'} />
            </div >
            <div className="faqMask"></div>
        </>
    )
}

export default Faq