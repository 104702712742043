import React from 'react'
import './certificate.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';


const Certificate = () => {
  return (
    <div className='certificateDiv' id='result'>
      <h2 className="mainHeader">
        РЕЗУЛЬТАТ АНАЛИЗА ПО 95 ПОКАЗАТЕЛЯМ
        <h3> ДО/ПОСЛЕ СО10-PREMIUM</h3>
      </h2>
      <div className="boxW">
        <Swiper
          effect={'cards'}
          grabCursor={true}
          navigation={true}
          modules={[EffectCards, Navigation]}
          initialSlide='1'
          cardsEffect={{
            perSlideOffset: 20, // Устанавливает отступ между слайдами
            perSlideRotate: 10, // Угол поворота каждого слайда
            rotate: false, // Включает вращение
            slideShadows: false, // Включает тени
          }}


          className="certificateSwiper"
        >
          <SwiperSlide className='certificateItem'><a rel="noopener noreferrer" target='_blank' href="https://pitierra.ru/static/img/certificate/certeficate_2.webp"><img src="/static/img/certificate/posle_2.webp" alt="" /></a></SwiperSlide>
          <SwiperSlide className='certificateItem'><a rel="noopener noreferrer" target='_blank' href="https://pitierra.ru/static/img/certificate/certeficate_1.webp"><img src="/static/img/certificate/posle_1.webp" alt="" /></a></SwiperSlide>
          <SwiperSlide className='certificateItem'><a rel="noopener noreferrer" target='_blank' href="https://pitierra.ru/static/img/certificate/certeficate_3.webp"><img src="/static/img/certificate/posle_3.webp" alt="" /></a></SwiperSlide>
        </Swiper>
        <p>Доказано экспертизой, проведенной Рязанская городская лаборатория анализа воды Лаборатория №12 УП НИОКР</p>
      </div>
    </div >
  )
}

export default Certificate