import React, { useEffect, useRef, useState } from 'react'
import './pay.scss'
import axios from "axios"
import { Link } from 'react-router-dom'
import URL from '../../env'

const Payment = (args) => {
    const [query, setQuery] = useState(null)
    const [month, setМonth] = useState(1)

    const [stance, setStance] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')

    const numberRef = useRef(null)
    const emailRef = useRef(null)
    const buttonRef = useRef(null)


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setQuery(params.get('pay'))
    }, [])

    const saveClient = () => {
        localStorage.setItem(`stance_${args.stage}`, stance)
        localStorage.setItem(`number_${args.stage}`, number)
        localStorage.setItem(`email_${args.stage}`, email)
    }

    const rememberClient = () => {
        var re_stance = localStorage.getItem(`stance_${args.stage}`)
        var re_number = localStorage.getItem(`number_${args.stage}`)
        var re_email = localStorage.getItem(`email_${args.stage}`)
        if (re_stance && re_stance !== 'null') {
            setStance(re_stance)
        }
        if (re_number && re_number !== 'null') {
            setNumber(re_number)
        }
        if (re_email && re_email !== 'null') {
            setEmail(re_email)
        }

    }


    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    useEffect(() => {
        rememberClient()
    }, [])


    const pay = () => {
        if (number && stance) {
            numberRef.current.classList.remove('error')
            if (email && validateEmail(email)) {
                saveClient()
                emailRef.current.classList.remove('error')
                buttonRef.current.disabled = true

                var token = getCookie('csrftoken')
                axios.post(`${URL}/api/pay`, {
                    month: month,
                    number: `СО${args.stage}№${stance}/${number}`,
                    email: email,
                    type: args.stage,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': token,
                    }
                }).then(request => {
                    buttonRef.current.disabled = false
                    window.location.href = request.data
                }).catch(request => {
                    buttonRef.current.disabled = false
                    numberRef.current.classList.add('error')
                    emailRef.current.classList.add('error')
                })

            } else {
                emailRef.current.classList.add('error')
            }

        } else {
            numberRef.current.classList.add('error')
        }
    }

    return (
        <div className='payDiv'>
            {query === 'success' ?

                <div className="succesPayment">
                    <img src="/static/img/pay/logo.png" alt="" />

                    <h1 className='mainHeader'>внимание</h1>
                    <p>Оплата прошла успешно! <br /> Спасибо за выбор <b>ПИТЬЕРРА!</b></p>
                    <Link to="/">Перейти на сайт</Link>
                    <div className="circle"></div>
                </div>
                :
                <>

                    <div className="pay">
                        <div className='info'>

                            <h2>
                                {args.stage == 5 ? 'ПЯТИСТУПЕНЧАТАЯ' : args.stage == 8 ? 'Восьмиступенчатая' : "десятиступенчатая"} СТАНЦИЯ</h2>
                            <div className='rangeInputSection'>
                                <div className='rangeInputDiv'>
                                    <input className="rangeInput" type="range" min="1" max="6" value={month} onChange={(e) => setМonth(e.target.value)} />
                                    <div>
                                        <span onClick={() => setМonth(1)}>1</span>
                                        <span onClick={() => setМonth(2)}>2</span>
                                        <span onClick={() => setМonth(3)}>3</span>
                                        <span onClick={() => setМonth(4)}>4</span>
                                        <span onClick={() => setМonth(5)}>5</span>
                                        <span onClick={() => setМonth(6)}>6</span>
                                    </div>
                                </div>
                                <div className="summDiv">
                                    <span>{month} <span>месяц</span></span>
                                    <h4>{args.stage == 5 ? 490 * month : args.stage == 8 ? 690 * month : 690 * month} руб</h4>
                                </div>
                            </div>
                            <div className="names">
                                <div>
                                    <span> Номер договора</span>
                                    <section ref={numberRef}>
                                        <h4>СО{args.stage}№</h4>
                                        <input value={stance} onChange={(e) => setStance(e.target.value)} type="text" />
                                        <h4>/</h4>
                                        <input value={number} onChange={(e) => setNumber(e.target.value)} type="text" />
                                    </section>
                                </div>
                                <div>
                                    <span> Email</span>
                                    <input ref={emailRef} value={email} onChange={(e) => setEmail(e.target.value.trim())} placeholder='Email' type="text" />
                                </div>
                            </div>
                            <button ref={buttonRef} onClick={pay} className='payButton'>Оплатить</button>
                        </div>
                    </div>
                </>
            }</div>
    )
}

export default Payment