import React, { useEffect } from 'react'
import './howWork.scss'
import Aos from 'aos'

const HowWork = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])
    return (
        <>
            <div className="waveDiv" id='waveDiv'></div>
            <div className='howWorkDiv'>
                <h1 className='mainHeader'>СИСТЕМА, КОТОРАЯ РАБОТАЕТ</h1>
                <div className='howWorks'>
                    <section>
                        <div data-aos="fade-right" className="howWorkBlock">
                            <h2>1</h2>
                            <p>Ступень: MECHANICAL</p>
                            <span>Каркасный картридж из вспененного полипропилена. Используется для очистки от механических примесей.</span>
                        </div>
                        <img className='pcImages' src="/static/img/howWork/filter1.png" alt="" />
                        <img className='mobileImages' src="/static/img/howWork/mobile/filter1.png" alt="" />

                    </section>

                    <section>
                        <img className='pcImages' src="/static/img/howWork/filter2.png" alt="" />
                        <div data-aos="fade-left" className="howWorkBlock">
                            <h2>2</h2>
                            <p>Ступень: IONIC RESIN</p>
                            <span>Предназначен для снижения содержания в воде солей кальция и магния. Препятствует образованию накипи в чайнике.
                                <br />
                                <br />
                                Состав картриджа: ионообменная смола + постфильтр 5 мкм.</span>
                        </div>
                        <img className='mobileImages' src="/static/img/howWork/mobile/filter2.png" alt="" />
                    </section>

                    <section>
                        <div data-aos="fade-right" className="howWorkBlock">
                            <h2>3</h2>
                            <p>Ступень: COCONUT COAL-P</p>
                            <span>Угольный блок для удаления хлора, органических веществ и механических загрязнений. 
                                <br />
                                <br />
                             Состав картриджа: прессованный кокосовый уголь </span>
                        </div>
                        <img className='pcImages' src="/static/img/howWork/filter3.png" alt="" />
                        <img className='mobileImages' src="/static/img/howWork/mobile/filter3.png" alt="" />
                    </section>

                    <section>
                        <img className='pcImages' src="/static/img/howWork/filter4.png" alt="" />
                        <div data-aos="fade-left" className="howWorkBlock">
                            <h2>4</h2>
                            <p>Ступень: COCONUT COAL-G</p>
                            <span>Предназначен для удаления хлора, органических веществ. 
                                <br />
                                <br />
                                Состав картриджа: гранулированный кокосовый уголь.</span>
                        </div>
                        <img className='mobileImages' src="/static/img/howWork/mobile/filter4.png" alt="" />
                    </section>

                    <section>
                        <div data-aos="fade-left" className="howWorkBlock">
                            <h2>5</h2>
                            <p>Ступень: SHUNGITE</p>
                            <span>Предназначен для снижения содержания в воде свободного хлора, хлороорганических соединений, нефтепродуктов, пестицидов, тяжелых металлов. Обладает антиоксидантным и бактерицидным действием. 
                                <br />
                                <br />
                                Состав картриджа: природный минерал шунгит.</span>
                        </div>
                        <img className='pcImages' src="/static/img/howWork/filter5.png" alt="" />
                        <img className='mobileImages' src="/static/img/howWork/mobile/filter5.png" alt="" />
                    </section>
                </div>
            </div>
        </>
    )
}

export default HowWork