import React from 'react'
import './ligth.scss'

const Ligth = (args) => {
  return (
    <div style={{zIndex: args.zIndex, opacity: args.opacity,transform:`translateX(${args.x}) translateY(${args.y})`,width:args.width,height:args.height,right:args.right,left:args.left}} className='ligtDiv'>

    </div>
  )
}

export default Ligth