// import Problems from '../../elements/problems/problems'
// import HowWork from '../../elements/howWork/howWork'
// import OurReviws from '../../elements/ourReviws/ourReviws'
// import Video from '../../elements/vode/vode'
import React from 'react'
import './main.scss'
import Head from '../../elements/head/head'
import Benefice from '../../elements/benefice/benefice'
import Calculator from '../../elements/calculator/calculator'
import FormItem from '../../elements/formItem/formItem'
import Faq from '../../elements/faq/faq'
import Certificate from '../../elements/certificate/certificate';
import Filling from '../../filling/filling';
import Station from '../../elements/station/station';
import Arend from '../../elements/aredn/arend';
import Installation from '../../elements/installation/installation';
import MainReviews from '../../elements/mainReviews/mainReviews';
import ArendCost from '../../elements/arendCost/arendCost';

const Main = () => {

  return (
    <>
      {/* <Video/> */}
      <Head />
      <Benefice />
      <Calculator />
      <Certificate />
      <Filling />
      <Station />
      <Arend />
      <Installation />
      <MainReviews />
      <ArendCost />
      <FormItem />
      <Faq />
    </>
  )
}

export default Main