import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Iframe from 'react-iframe'
import './footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
    const loading = useSelector((state) => state.loader.loaded);
    useEffect(() => {
        if (loading) {
            if (window.location.hash) {
                window.location.href = window.location.hash;
            }
        }
    }, [loading])

    const locationTo = (location) => {
        window.location.href = location
    }
    return (
        <>
            <div className='smooth'></div>
            <div className='footer'>
                <div className="logo">
                    <h1>питьерра</h1>
                    <h4>территория чистой воды</h4>
                </div>
                <div className="routing">
                    <span>Содержание</span>
                    <Link to="/">Главная страница</Link>
                    <Link to="/service">Обслуживание</Link>
                    <Link to="/reviews">Отзывы</Link>
                    <Link to="/contact">Контакты</Link>
                    <Link to="/pay">Оплатить аренду</Link>
                </div>
                <div className="routing">
                    <span>Разделы сайта</span>
                    <p onClick={() => locationTo('/#benefice')} >Преимущество станции</p>
                    <p onClick={() => locationTo('/#result')} >Результат анализа</p>
                    <p onClick={() => locationTo('/#filling')} >Наполнение станции</p>
                    <p onClick={() => locationTo('/#station')} >Параметры станции</p>
                    <p onClick={() => locationTo('/#installation')} >Установка станции</p>
                    <p onClick={() => locationTo('/#reviews')} >Отзывы клиентов</p>
                    <p onClick={() => locationTo('/#form')} >Оставить заявку</p>
                    <p onClick={() => locationTo('/#faq')} >Вопросы / Ответы</p>
                </div>
                <div className="routing">
                    <span>Контакты</span>
                    <a href="tel:+74912522922"> <img src="/static/img/footer/call.svg" alt="" />+7 (4912) 522-922</a>
                    <a href="mailto:watercode@gmail.com"> <img src="/static/img/footer/mail.svg" alt="" />info@pitierra.ru</a>
                    <a href=""> <img src="/static/img/footer/mark.svg" alt="" />390013, Рязанская область, г Рязань, ул Ситниковская, д. 69А , офис 22</a>
                    {/* <iframe src="" width="401" height="295" frameborder="0"></iframe> */}
                    <Iframe url="https://yandex.ru/map-widget/v1/?um=constructor%3A670bf24abf699cdeb179c672cb0834403e580a5f1b2d0371231c081cc2e44f8d&amp;source=constructor&style=stylers.lightness:invert"
                        display="block"
                        position="relative" />
                </div>
                <div className="bottom">
                    <div>
                        <span>
                            ООО "ПИТЬЕРРА"
                        </span>
                        <span>
                            ИНН: 6234205773
                        </span>
                        <span>
                            КПП: 623401001
                        </span>
                        <span>
                            ОГРН: 1236200008571
                        </span>
                        <hr />
                        <span>
                            <a target='_blank' href="/static/file/arend.pdf">Договор аренды</a>
                        </span>
                        <span>
                            <a target='_blank' href="/static/file/privacy_policy.pdf">Политика конфиденциальность</a>
                        </span>
                        <span>Все права защищены © 2023.</span>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer

