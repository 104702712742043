import './widget.css'
import { useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';


export default function ButtonWidget() {
  const circle = useRef()
  const chatWord = useRef()
  const manuButton = useRef()



  function circleAnimation() {
  }
  function openMsmsm() {
    setInterval(() => {
      chatWord.current.style.transform = 'scale(1)'
      chatWord.current.style.right = '90px'
      setTimeout(() => {
        chatWord.current.style.transform = 'scale(0)'
        chatWord.current.style.right = '0'

      }, 6000);
    }, 16000);
  }
  function autoOpenMsmsm() {
    // setTimeout(() => {
    //   if (window.screen.width >= 600) {
    //     manuButton.current.classList.add('activeWidgetManu')
    //     manuButton.current.style.filter = 'blur(2px)'
    //     chatWord.current.style.transform = 'scale(0)'
    //     chatWord.current.style.right = '0'
    //     setTimeout(() => {
    //       manuButton.current.style.filter = 'blur(0px)'
    //     }, 150);
    //     setTimeout(() => {
    //       chatWord.current.style.display = 'none'
    //     }, 300);
    //   }
    // }, 10000);
  }


  useEffect(() => {
    circleAnimation()

    setTimeout(() => {
      manuButton.current.classList.add('activeWidgetManu')

      chatWord.current.style.transform = 'scale(0)'
      chatWord.current.style.right = '0'
      setTimeout(() => {
        chatWord.current.style.display = 'none'
      }, 300);
    }, 13000);

    openMsmsm()
    autoOpenMsmsm()

  }, [])
  function openManuButton() {
    manuButton.current.classList.toggle('activeWidgetManu')

    chatWord.current.style.transform = 'scale(0)'
    chatWord.current.style.right = '0'
    setTimeout(() => {
      chatWord.current.style.display = 'none'
    }, 300);
  }
  function closeManuButton() {
    manuButton.current.classList.remove('activeWidgetManu')
  }
  return (
    <>
      <div className="buttonWidget">
        <div ref={manuButton} onClick={closeManuButton} className='widgetManu'>
          <div className='closewidgetManu'></div>
          <p>Свяжитесь с нами прямо сейчас</p>
          <a href="tel:522922">
            <div style={{ backgroundColor: '#37C837' }}><img src="/static/img/widget/svgexport-16.png" alt="" /></div>
            <p>522-922</p>
            <span>Позвоните нам</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://t.me/pitierra_ru">
            <div style={{ backgroundColor: '#139BD0' }}><img src="/static/img/widget/svgexport-15.png" alt="" /></div>
            <p>Telegram</p>
            <span>Напишите нам в Telegram</span>
          </a>

          <a target="_blank" rel="noreferrer" href="https://wa.me/79009667008">
            <div style={{ backgroundColor: '#25D366' }}><img src="/static/img/widget/svgexport-13.png" alt="" /></div>
            <p>Whatsapp</p>
            <span>Напишите нам в Whatsapp</span>
          </a>
          <a target="_blank" rel="noreferrer" href="https://vk.com/pitierra_ru">
            <div style={{ backgroundColor: '#4b729f' }}><img src="/static/img/widget/vk.png" alt="" /></div>
            <p>Вконтакте</p>
            <span>Подписаться на группу ВК</span>
          </a>

        </div>
        <div ref={chatWord} className='chatWord'>
          <p>Есть вопросы? Обращайтесь!</p>
        </div>
        <div onClick={openManuButton} ref={circle} className='circleButtonWidget'>
          <img className='msmsm' src="/static/img/widget/support2.png" alt="" />
        </div>
      </div>
    </>
  );
}

