import React, { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask';
import './form.scss'
import Aos from 'aos'
import axios from 'axios';
import URL from '../../env';

const FormItem = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 })
  }, [])

  const [name, setName] = useState(null)
  const [number, setNumber] = useState('')
  const [polity, setPolity] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [success, setSuccess] = useState('')

  const buttonRef = useRef(null)

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }


  const sendForm = () => {
    buttonRef.current.disabled = true
    var token = getCookie('csrftoken')
    if (name) {
      setErrorText('')
      var clean_number = number.replace(/\D/g, '')
      if (number && clean_number.length == 11) {
        setErrorText('')
        if (polity) {
          setErrorText('')

          axios.post(`${URL}/api/form`, {
            number: clean_number,
            name: name,
            mode: 1,
          }, {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': token,
            }
          }).then(request => {
            buttonRef.current.disabled = false
            setSuccess('Заявка отправлена, скоро с вами свяжутся наши сотрудники.')
            setName('')
            setNumber('')
          }).catch(request => {
            buttonRef.current.disabled = false
            setErrorText('Ошибка обращайтесь в службу поддержки.')
            console.log(request.data)
          })
        }
        else {
          setErrorText('Вы не приняли политику конфиденциальности.')
          buttonRef.current.disabled = false
        }
      }
      else {
        setErrorText('Пожалуйста заполните номер.')
        buttonRef.current.disabled = false
      }
    }
    else {
      setErrorText('Пожалуйста заполните имя.')
      buttonRef.current.disabled = false
    }
  }



  return (
    <div className='formItemDiv'>
      <h1 className="mainHeader">Оставить заявку</h1>
      <div className='formItem'>
        <div data-aos="fade-right" className='logo'>
          <h1>питьерра</h1>
          <h2>Экономим деньги, бережём здоровье</h2>
        </div>
        <div className='formSection'>
          <p>Мы свяжемся с вами в течение <b>5 мин</b> и ответим на все ваши вопросы по установке станции!</p>
          <div>
            <img src="/static/img/form/user.svg" alt="" />
            <input type="text" placeholder='Имя' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <img src="/static/img/form/phone.svg" alt="" />
            <InputMask maskChar=" " mask="9 (999) 999-99-99" placeholder='Номер' type="text" value={number} onChange={(e) => setNumber(e.target.value)} />
          </div>
          <span>
            <input checked={polity} onChange={(e) => setPolity(e.target.checked)} type="checkbox" id="horns" hidden />
            <label for="horns">
              {polity ?
                <img src="/static/img/form/on.png" alt="" />
                :
                <img src="/static/img/form/off.png" alt="" />
              }
              Ставя галочку вы даете свое согласие на обработку персональных данных и принимаете политику конфиденциальности компании.
            </label>
          </span>
          {success && <p className='success'>{success}</p>}
          {errorText && <p className='errorText'>{errorText}</p>}
          <button ref={buttonRef} onClick={sendForm} >Отправить заявку</button>
        </div>
        <div className='logo'>
          <img src="/static/img/form/famaly.png" alt="" />
        </div>
      </div>
    </div>
  )
}
export default FormItem
