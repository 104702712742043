import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import './mainReviews.scss'
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';
const reviewList = ["review (1).png", "review (2).png", "review (3).png", "review (4).png", "review (5).png", "review (6).png", "review (7).png", "review (8).png", "review (9).png", "review (10).png", "review (11).png", "review (12).png", "review (13).png", "review (14).png", "review (15).png", "review (16).png", "review (17).png", "review (18).png", "review (19).png", "review (20).png", "review (21).png", "review (22).png", "review (23).png", "review (24).png", "review (25).png", "review (26).png", "review (27).png", "review (28).png", "review (29).png", "review (30).png", "review (31).png", "review (32).png", "review (33).png", "review (34).png", "review (35).png"]
const reviewMobile = [["review (20).png", "review (21).png", "review (22).png"],["review (1).png", "review (3).png", "review (4).png"], ["review (5).png", "review (6).png", "review (7).png"], ["review (8).png", "review (9).png", "review (10).png"], ["review (11).png", "review (12).png", "review (13).png"], ["review (14).png", "review (15).png", "review (16).png"], ["review (17).png", "review (18).png", "review (19).png"], ["review (23).png", "review (24).png", "review (25).png"], ["review (26).png", "review (27).png", "review (28).png"], ["review (29).png", "review (30).png", "review (31).png"], ["review (32).png", "review (33).png", "review (34).png"], ["review (2).png", "review (35).png"]]
const slide_1 = ["review (1).png", "review (3).png", "review (4).png", "review (5).png", "review (6).png", "review (7).png", "review (8).png", "review (9).png", "review (10).png", "review (11).png", "review (12).png"]
const slide_2 = ["review (13).png", "review (14).png", "review (15).png", "review (16).png", "review (17).png", "review (18).png", "review (19).png", "review (20).png", "review (21).png", "review (22).png", "review (23).png", "review (24).png"]
const slide_3 = ["review (25).png", "review (26).png", "review (27).png", "review (28).png", "review (29).png", "review (30).png", "review (31).png", "review (32).png", "review (33).png", "review (34).png", "review (35).png"]

const MainReviews = () => {
    const [currentSlide, setCurrentSlide] = useState(1); // Текущий слайд
    const [totalSlides, setTotalSlides] = useState(0);  // Общее количество слайдов

    const handleSwiperInit = (swiper) => {
        setTotalSlides(swiper.slides.length); // Устанавливаем общее количество слайдов
    };

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.activeIndex + 1); // Обновляем текущий слайд (activeIndex начинается с 0)
    };

    return (
        <div className='mainReviewDiv' id='reviews'>
            <div className="boxW">
                <div className="swiperDiv ">
                    <h3>Отзывы клиентов
                        <h4>{currentSlide} / {reviewList.length}</h4>
                    </h3>

                    <Swiper
                        onSwiper={handleSwiperInit} // Инициализация Swiper
                        onSlideChange={handleSlideChange} // Событие смены слайда
                        navigation={true}
                        modules={[Navigation]}
                        className="swiperRev"
                    >
                        {
                            reviewList.map((name, i) => (
                                <SwiperSlide key={i} className='swipeItem'><img src={`/static/img/reviews/reviews/${name}`} alt="" /></SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                <div className="swiperDiv swiperDivMobile">
                    <h3>Отзывы клиентов
                    </h3>

                    <Swiper
                        onSwiper={handleSwiperInit} // Инициализация Swiper
                        onSlideChange={handleSlideChange} // Событие смены слайда
                        navigation={true}
                        modules={[Navigation]}
                        className="swiperRev"
                    >
                        {
                            reviewMobile.map(items => (
                                <SwiperSlide className='swipeItem'>
                                    {items.map((img) => (
                                        <img key={img} src={`/static/img/reviews/reviews/${img}`} alt="" />
                                    ))}
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <h4>{currentSlide} / {reviewMobile.length}</h4>
                </div>
                <div className="miniReviewsDiv">
                    <Swiper
                        className='examplesDiv'
                        autoplay={{
                            delay: 3000, // Задержка в миллисекундах между прокруткой                            
                            disableOnInteraction: true, // Продолжить автопрокрутку после взаимодействия
                        }}
                        // navigation={true}
                        // pagination={true}
                        slidesPerView={5}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                    >
                        {slide_1.map(name =>
                            <SwiperSlide className='examples' key={name}>
                                <img src={`/static/img/reviews/reviews/${name}`} alt="" />
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <Swiper
                        className='examplesDiv'
                        autoplay={{
                            delay: 3000, // Задержка в миллисекундах между прокруткой
                            reverseDirection: true, // Прокрутка в обратную сторону
                            disableOnInteraction: true, // Продолжить автопрокрутку после взаимодействия
                        }}
                        // navigation={true}
                        // pagination={true}
                        slidesPerView={5}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                    >
                        {slide_2.map(name =>
                            <SwiperSlide className='examples' key={name}>
                                <img src={`/static/img/reviews/reviews/${name}`} alt="" />
                            </SwiperSlide>
                        )}
                    </Swiper>
                    <Swiper
                        className='examplesDiv'
                        autoplay={{
                            delay: 3000, // Задержка в миллисекундах между прокруткой                            
                            disableOnInteraction: false, // Продолжить автопрокрутку после взаимодействия
                        }}
                        slidesPerView={5}
                        modules={[Navigation, Mousewheel, Keyboard, Autoplay]}
                    >
                        {slide_3.map(name =>
                            <SwiperSlide className='examples' key={name}>
                                <img src={`/static/img/reviews/reviews/${name}`} alt="" />
                            </SwiperSlide>
                        )}
                    </Swiper>
                    {/* <section className='firstSection'>
                        <section>
                            <div>
                                <img src="/static/img/reviews/rev_1.png" alt="" />
                                <img src="/static/img/reviews/rev_2.png" alt="" />
                            </div>
                            <div>
                                <img src="/static/img/reviews/rev_2.png" alt="" />
                                <img src="/static/img/reviews/rev_1.png" alt="" />
                            </div>
                        </section>
                        <img src="/static/img/reviews/review.png" alt="" />
                    </section>
                    <section className='firstSection'>
                        <img src="/static/img/reviews/review.png" alt="" />
                        <section>
                            <div>
                                <img src="/static/img/reviews/rev_1.png" alt="" />
                                <img src="/static/img/reviews/rev_2.png" alt="" />
                            </div>
                            <div>
                                <img src="/static/img/reviews/rev_2.png" alt="" />
                                <img src="/static/img/reviews/rev_1.png" alt="" />
                            </div>
                        </section>
                    </section> */}
                </div>
                <div className="circle"></div>
            </div>

        </div>
    )
}

export default MainReviews